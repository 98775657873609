/* eslint-disable class-methods-use-this */
import * as Sentry from '@sentry/react'
import { environment } from '@awell/libs-web/environment'
import { type ErrorContext, type ErrorReporter } from './types'
import { isNil } from 'lodash'

export class SentryErrorReporter implements ErrorReporter {
  constructor() {
    Sentry.init({
      environment: environment.environment,
      dsn: environment.logging.crash_reporter.pathway_studio,
      release: environment.releases.pathway_studio,
      ignoreErrors: [],
      // Increase depth for better GraphQL error details
      normalizeDepth: 10,

      // Add additional context for GraphQL errors
      beforeSend(event) {
        if (event.exception?.values?.[0]?.type === 'GraphQLError') {
          event.extra = {
            ...event.extra,
            graphqlOperation: event.extra?.operationName,
            graphqlVariables: event.extra?.variables,
            graphqlPath: event.extra?.path,
            graphqlExtensions: event.extra?.extensions,
          }
        }
        return event
      },
    })
  }

  public setContext(context: ErrorContext): void {
    Sentry.setUser(context.user)
    if (!isNil(context.user.organization_slug)) {
      Sentry.setTag('organization_slug', context.user.organization_slug)
    }
  }

  // See https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly
  // Unfortunately the capture context type isn't exported by sentry sdk so
  // instead we use a generic object
  public report(error: Error, captureContext?: Record<string, any>): void {
    // Skip crash reports in local to:
    // - reduce noise in sentry
    // - avoid impacting sentry quotas
    if (environment.environment !== 'local') {
      Sentry.captureException(error, captureContext)
    } else {
      console.error(error, captureContext)
    }
  }
}
